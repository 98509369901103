import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['productsWrapper', 'addProductButton', 'removeProductButton',
    'unitPrice', 'finalPrice', 'percentageOnUnitPrice', 'quantity', 'priceTotal', 
    'installments', 'dueDatesContainer'
  ];

  connect() {
    this.formatAllCurrencies()
    
  }

  initialize() {
    this.updateDueDatesFields();
  }

  formatAllCurrencies(){
    this.element.querySelectorAll('.formatted-currency').forEach((input) => {
      input.addEventListener('input', this.formatCurrency.bind(this));
    });
  }

  formatCurrency(event) {
    const input = event.target;
    let value = input.value.replace(/\D/g, '');

    // Se o valor for zero, não exiba nada
    if (value === '0') {
      input.value = '';
      return;
    }

    if (value === '') {
      return;
    }

    // Obter parte inteira e decimal
    let integerPart = value.slice(0, -2);
    const decimalPart = value.slice(-2);

    // Adicionar separador de milhares
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Formatar a parte decimal
    const formattedValue = integerPart + ',' + decimalPart;

    input.value = formattedValue;
  }

  calculatePriceTotal(element) {
    let divItem = this.getItemsDiv(element.target)
    let priceTotalElement = divItem.querySelector(`[data-target="supplier-transactions.priceTotal"]`);
    let quantityElement = divItem.querySelector(`[data-target="supplier-transactions.quantity"]`);
    let unityElement = divItem.querySelector(`[data-target="supplier-transactions.unitPrice"]`);

    const quantity = parseFloat(quantityElement.value.replace(/[^0-9,-]+/g, "").replace(',', '.')) || 0;
    const unitPrice = parseFloat(unityElement.value.replace(/[^0-9,-]+/g, "").replace(',', '.')) || 0;

    const priceTotal = quantity * unitPrice;

    priceTotalElement.value = parseFloat(priceTotal.toFixed(2)).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    });
  }

  getItemsDiv(elementTarget) {
    const parentElement = elementTarget.parentElement.parentElement;
    return parentElement.classList.contains("items") ? parentElement : parentElement.parentElement;
  }

  updateFinalPrice(element) {
    let divItem = this.getItemsDiv(element.target)
    let finalPriceElement = divItem.querySelector(`[data-target="supplier-transactions.finalPrice"]`);
    let percentageOnUnitPriceElement = divItem.querySelector(`[data-target="supplier-transactions.percentageOnUnitPrice"]`);
    let unityElement = divItem.querySelector(`[data-target="supplier-transactions.unitPrice"]`);

    const unitPrice = parseFloat(unityElement.value.replace(/[^0-9,-]+/g, "").replace(',', '.')) || 0;
    const percentageOnUnitPrice = parseFloat(percentageOnUnitPriceElement.value.replace(/[^0-9,.]+/g, "").replace(',', '.')) || 0;

    const priceTotal = unitPrice + ((percentageOnUnitPrice / 100) * unitPrice);

    finalPriceElement.value = parseFloat(priceTotal.toFixed(2)).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    });
  }

  addProduct(){
    var itemsCount = document.querySelectorAll('.items').length

    var divFormRow = document.createElement("div")
    divFormRow.classList.add('form-row')
    divFormRow.classList.add('items')

    var hr = document.createElement("custom-hr")
    hr.classList.add("custom-hr")
    divFormRow.appendChild(hr)

    var productIndexSpan = document.createElement("span");
    productIndexSpan.classList.add('product-title');
    productIndexSpan.innerHTML = "<strong>" + (itemsCount + 1) + "º item</strong>";

    var productIndexFormGroup = document.createElement("div")
    productIndexFormGroup.classList.add("form-group")
    productIndexFormGroup.classList.add("col-md-12")
    productIndexFormGroup.appendChild(productIndexSpan)
    divFormRow.appendChild(productIndexFormGroup)

    //Product Id
    var productPrompt = "Selecione o produto";
    var productIdDivFormGroup = document.createElement("div")
    productIdDivFormGroup.classList.add("form-group")
    productIdDivFormGroup.classList.add("col-md-4")

    var productIdLabel = document.createElement("label")
    productIdLabel.innerHTML = "Produto da Nota"

    var productIdSpan = document.createElement("span")
    productIdSpan.classList.add("asterisk")
    productIdSpan.innerHTML = "*"

    var productIdSelect = document.createElement("select")
    productIdSelect.classList.add("form-control")
    productIdSelect.classList.add("select2")
    productIdSelect.name = "supplier_transaction[supplier_transaction_items_attributes][" + itemsCount + "][product_id]"
    var productIdPromptOption = document.createElement("option");
    productIdPromptOption.value = "";
    productIdPromptOption.text = productPrompt;
    productIdSelect.appendChild(productIdPromptOption);

    var productIdBlank = document.createElement('option')
    productIdBlank.innerHTML = ''
    productIdSelect.appendChild(productIdBlank)
    this.loadProducts(productIdSelect)

    productIdDivFormGroup.appendChild(productIdLabel)
    productIdDivFormGroup.appendChild(productIdSpan)
    productIdDivFormGroup.appendChild(productIdSelect)

    divFormRow.appendChild(productIdDivFormGroup)

    //Quantity
    var quantityDivFormGroup = document.createElement("div")
    quantityDivFormGroup.classList.add("form-group")
    quantityDivFormGroup.classList.add("col-md-4")

    var quantityLabel = document.createElement("label")
    quantityLabel.innerHTML = "Quantidade"

    var quantitySpan = document.createElement("span")
    quantitySpan.classList.add("asterisk")
    quantitySpan.innerHTML = "*"

    var quantityTextField = document.createElement("input")
    quantityTextField.type = "text"
    quantityTextField.name = "supplier_transaction[supplier_transaction_items_attributes][" + itemsCount + "][quantity]"
    quantityTextField.dataset.target = "supplier-transactions.quantity"
    quantityTextField.dataset.action = "keyup->supplier-transactions#calculatePriceTotal"
    quantityTextField.classList.add('form-control')

    quantityDivFormGroup.appendChild(quantityLabel)
    quantityDivFormGroup.appendChild(quantitySpan)
    quantityDivFormGroup.appendChild(quantityTextField)

    divFormRow.appendChild(quantityDivFormGroup)

    //Unit Price
    var unitPriceDivFormGroup = document.createElement("div")
    unitPriceDivFormGroup.classList.add("form-group")
    unitPriceDivFormGroup.classList.add("col-md-4")

    var unitPriceLabel = document.createElement("label")
    unitPriceLabel.innerHTML = "Preço da Unidade do Produto"

    var unitPriceSpan = document.createElement("span")
    unitPriceSpan.classList.add("asterisk")
    unitPriceSpan.innerHTML = "*"

    var unitPriceInputGroup = document.createElement("div")
    unitPriceInputGroup.classList.add("input-group")

    var unitPriceInputPrependGroup = document.createElement("div")
    unitPriceInputPrependGroup.classList.add("input-group-prepend")

    var unitPriceInputGroupTextSpan = document.createElement("span")
    unitPriceInputGroupTextSpan.classList.add("input-group-text")
    unitPriceInputGroupTextSpan.innerHTML = "R$"

    unitPriceInputPrependGroup.appendChild(unitPriceInputGroupTextSpan)
    unitPriceInputGroup.appendChild(unitPriceInputPrependGroup)

    var unitPriceTextField = document.createElement("input")
    unitPriceTextField.type = "text"
    unitPriceTextField.name = "supplier_transaction[supplier_transaction_items_attributes][" + itemsCount + "][unit_price]"
    unitPriceTextField.dataset.target = "supplier-transactions.unitPrice"
    unitPriceTextField.dataset.action = "keyup->supplier-transactions#calculatePriceTotal keyup->supplier-transactions#updateFinalPrice"
    unitPriceTextField.classList.add('form-control')
    unitPriceTextField.classList.add('formatted-currency')
    unitPriceInputGroup.appendChild(unitPriceTextField)

    unitPriceDivFormGroup.appendChild(unitPriceLabel)
    unitPriceDivFormGroup.appendChild(unitPriceSpan)
    unitPriceDivFormGroup.appendChild(unitPriceInputGroup)

    divFormRow.appendChild(unitPriceDivFormGroup)

    //Price Total
    var priceTotalDivFormGroup = document.createElement("div")
    priceTotalDivFormGroup.classList.add("form-group")
    priceTotalDivFormGroup.classList.add("col-md-4")

    var priceTotalLabel = document.createElement("label")
    priceTotalLabel.innerHTML = "Preço Total do Produto na Nota"

    var priceTotalSpan = document.createElement("span")
    priceTotalSpan.classList.add("asterisk")
    priceTotalSpan.innerHTML = "*"

    var priceTotalInputGroup = document.createElement("div")
    priceTotalInputGroup.classList.add("input-group")

    var priceTotalInputPrependGroup = document.createElement("div")
    priceTotalInputPrependGroup.classList.add("input-group-prepend")

    var priceTotalInputGroupTextSpan = document.createElement("span")
    priceTotalInputGroupTextSpan.classList.add("input-group-text")
    priceTotalInputGroupTextSpan.innerHTML = "R$"

    priceTotalInputPrependGroup.appendChild(priceTotalInputGroupTextSpan)
    priceTotalInputGroup.appendChild(priceTotalInputPrependGroup)

    var priceTotalTextField = document.createElement("input")
    priceTotalTextField.type = "text"
    priceTotalTextField.name = "supplier_transaction[supplier_transaction_items_attributes][" + itemsCount + "][price_total]"
    priceTotalTextField.classList.add('form-control')
    priceTotalTextField.dataset.target = "supplier-transactions.priceTotal"
    priceTotalInputGroup.appendChild(priceTotalTextField)

    priceTotalDivFormGroup.appendChild(priceTotalLabel)
    priceTotalDivFormGroup.appendChild(priceTotalSpan)
    priceTotalDivFormGroup.appendChild(priceTotalInputGroup)

    divFormRow.appendChild(priceTotalDivFormGroup);

    //Percentage on unit price
    var percentageOnUnitPriceDivFormGroup = document.createElement("div");
    percentageOnUnitPriceDivFormGroup.classList.add("form-group");
    percentageOnUnitPriceDivFormGroup.classList.add("col-md-4");

    var percentageOnUnitPriceLabel = document.createElement("label");
    percentageOnUnitPriceLabel.innerHTML = "Porcentagem para Venda do Produto";

    // var percentageOnUnitPriceSpan = document.createElement("span");
    // percentageOnUnitPriceSpan.classList.add("asterisk");
    // percentageOnUnitPriceSpan.innerHTML = "*";

    var percentageOnUnitPriceTextField = document.createElement("input");
    percentageOnUnitPriceTextField.type = "text";
    percentageOnUnitPriceTextField.name = "supplier_transaction[supplier_transaction_items_attributes][" + itemsCount + "][percentage_on_unit_price]";
    percentageOnUnitPriceTextField.dataset.target = "supplier-transactions.percentageOnUnitPrice"
    percentageOnUnitPriceTextField.dataset.action = "keyup->supplier-transactions#updateFinalPrice"
    percentageOnUnitPriceTextField.classList.add("form-control");

    percentageOnUnitPriceDivFormGroup.appendChild(percentageOnUnitPriceLabel);
    // percentageOnUnitPriceDivFormGroup.appendChild(percentageOnUnitPriceSpan);
    percentageOnUnitPriceDivFormGroup.appendChild(percentageOnUnitPriceTextField);

    divFormRow.appendChild(percentageOnUnitPriceDivFormGroup);

    //Final Price
    var finalPriceDivFormGroup = document.createElement("div")
    finalPriceDivFormGroup.classList.add("form-group")
    finalPriceDivFormGroup.classList.add("col-md-4")

    var finalPriceLabel = document.createElement("label")
    finalPriceLabel.innerHTML = "Preço de Venda do Produto"

    // var finalPriceSpan = document.createElement("span")
    // finalPriceSpan.classList.add("asterisk")
    // finalPriceSpan.innerHTML = "*"

    var finalPriceInputGroup = document.createElement("div")
    finalPriceInputGroup.classList.add("input-group")

    var finalPriceInputPrependGroup = document.createElement("div")
    finalPriceInputPrependGroup.classList.add("input-group-prepend")

    var finalPriceInputGroupTextSpan = document.createElement("span")
    finalPriceInputGroupTextSpan.classList.add("input-group-text")
    finalPriceInputGroupTextSpan.innerHTML = "R$"

    finalPriceInputPrependGroup.appendChild(finalPriceInputGroupTextSpan)
    finalPriceInputGroup.appendChild(finalPriceInputPrependGroup)

    var finalPriceTextField = document.createElement("input")
    finalPriceTextField.type = "text"
    finalPriceTextField.name = "supplier_transaction[supplier_transaction_items_attributes][" + itemsCount + "][final_price]"
    finalPriceTextField.dataset.target = "supplier-transactions.finalPrice"
    finalPriceTextField.classList.add('form-control')
    finalPriceInputGroup.appendChild(finalPriceTextField)

    finalPriceDivFormGroup.appendChild(finalPriceLabel)
    // finalPriceDivFormGroup.appendChild(finalPriceSpan)
    finalPriceDivFormGroup.appendChild(finalPriceInputGroup)

    divFormRow.appendChild(finalPriceDivFormGroup);

    this.productsWrapperTarget.append(divFormRow)

    this.removeProductButtonTarget.classList.remove('is-display-none')

    this.formatAllCurrencies()
  }

  removeProduct(a){
    const child = this.productsWrapperTarget.lastElementChild
    if (child == null) {
        return false
    } else {
      let answer = null
      if (a == true){
        answer = a
      } else {
        answer = this.removeItemConfirmationBox()
      }
      if (answer) {
        this.productsWrapperTarget.lastElementChild.remove()
      }
    }
    document.querySelectorAll("div[data-target='supplier-transactions.productsWrapper'] .items").length > 0 ? this.removeProductButtonTarget.classList.remove("is-display-none") : this.removeProductButtonTarget.classList.add("is-display-none")
  }

  removeItemConfirmationBox() {
    let answer = window.confirm("Tem certeza que deseja remover este item?")
    return answer
  }

  loadProducts(productIdSelect) {
    fetch("/produtos/listar")
      .then(function(response) {
        return response.json()
      })
      .then(function(data) {
        data.map(function(product) {
          var productIdItem = document.createElement('option')
          productIdItem.value = product.id
          productIdItem.innerHTML = product.name
          productIdSelect.appendChild(productIdItem)
        })
      })
  }

  updateDueDatesFields() {
    const numberOfInstallments = parseInt(this.installmentsTarget.value, 10) || 0;
    const existingDueDates = JSON.parse(this.dueDatesContainerTarget.dataset.existingDueDates || "[]");
    const existingFields = this.dueDatesContainerTarget.querySelectorAll('div.form-group');
    
    existingFields.forEach(field => field.remove());
  
    for (let i = 0; i < existingDueDates.length; i++) {
      this.addDueDateField(i + 1, existingDueDates[i]);
    }
  
    for (let i = existingDueDates.length + 1; i <= numberOfInstallments; i++) {
      this.addDueDateField(i);
    }
  }
  
  addDueDateField(index, value = '') {
    const div = document.createElement("div");
    div.classList.add('form-group');
    div.classList.add('col-md-4');
    
    const label = document.createElement("label");
    label.innerText = `${index}ª Data de Vencimento `;
    
    const input = document.createElement("input");
    input.type = "date";
    input.name = `supplier_transaction[due_dates][]`;
    input.classList.add('form-control');
    input.value = value; 
    
    div.appendChild(label);
    div.appendChild(input);
    
    this.dueDatesContainerTarget.appendChild(div);
  }
  
  installmentsTargetChanged() {
    this.updateDueDatesFields();
  }
}
