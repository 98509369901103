import { Controller } from "stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      closeOnSelect: false,
      settings: {
        searchPlaceholder: 'Pesquisar cliente',
        searchText: 'Sem resultado',
        searchingText: 'Buscando...',
      },
      events: {
        search: (search, currentData) => {
          return new Promise((resolve, reject) => {
            if (search.length < 2) {
              resolve([]);
              return;
            }

            fetch(`/clientes/buscar?term=${search}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then((response) => response.json())
              .then((data) => {
                const options = data.map((customer) => {
                  return {
                    text: customer.label,
                    value: customer.value,
                  };
                });

                resolve(options);
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
      },
    });
  }
}