import { Controller } from "stimulus";
import BaseController from "./base_controller";

export default class extends BaseController {
  connect() {
    this.element.querySelectorAll('.formatted-currency').forEach((input) => {
      input.addEventListener('input', this.formatCurrency.bind(this));
    });
  }
  
}