import { Controller } from "stimulus";
import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ ]

  initialize() {

  }

  connect() {

  }

}