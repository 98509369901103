import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["discount", "finalPrice", "installments", "installmentsValue", "interestRate", "totalPrice", "totalAmount"];
  
  connect() {
    const statusElement = document.querySelector('[data-target="order.status"]');
    const status = statusElement.textContent.trim();

    if (status === 'Caixa' || status === '' || status === 'null') {

      this.calculateFinalPrice();
      this.calculateInstallmentsValue();
      this.totalPrices();
      this.updateTotalAmount();
    } 
  }

  initialize() {
    console.log("Controller initialized");
    this.installmentsTarget.addEventListener("input", () => {
      this.calculateInstallmentsValue();
    });
    this.discountTarget.addEventListener("input", () => {
      this.calculateFinalPrice();
    });
    this.interestRateTarget.addEventListener("input", () => {
      this.totalPrices();
    });
    this.interestRateTargets.forEach((interestRateTarget) => {
      interestRateTarget.addEventListener('input', () => {
        this.totalPrices();
        this.updateTotalAmount(); 
        this.updateinstallmentsValue();
        this.updatefinalPrice();
      });
    })    
    this.discountTarget.addEventListener("focus", () => {
      // Verificar se o valor atual é "0.0" e limpar se for
      if (this.discountTarget.value === "0.0") {
        this.discountTarget.value = "";
      }
    });
  };

  totalPrices() {
    this.interestRateTargets.forEach((interestRateTarget, index) => {
      const interestRateValue = interestRateTarget.value;
      const interestRate = parseFloat(interestRateValue.replace(/[^\d.]/g, '').replace(',', '.')) || 0;
      const totalPriceInput = this.totalPriceTargets[index];
      const originalPriceValue = totalPriceInput.dataset.originalPrice;
      const originalPrice = parseFloat(originalPriceValue.replace(/[^\d.]/g, '').replace(',', '.')) || 0;
 
      const totalPrice = originalPrice + (originalPrice * interestRate / 100);

      totalPriceInput.value = parseFloat(totalPrice.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    });
  }
  calculateFinalPrice() {
    let totalPrice = 0;
  
    // Percorra todos os elementos de totalPriceTargets e some os valores
    this.totalPriceTargets.forEach((totalPriceInput) => {
      const currentTotalPrice = parseFloat(totalPriceInput.value.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
      totalPrice += currentTotalPrice;
    });
  
    // Obter o desconto do elemento de desconto
    const discount = parseFloat(this.discountTarget.value.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
  
    // Calcular o preço final com base no valor total e no desconto
    const finalPrice = totalPrice - (totalPrice * discount / 100);
      // Se o desconto for zero, definir o totalPrice novamente

    if (discount === 0) {
      // Reinicializar totalPrice com a soma dos valores originais
      totalPrice = 0;
      this.totalPriceTargets.forEach((totalPriceInput) => {
        const originalTotalPrice = parseFloat(totalPriceInput.dataset.originalPrice.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
        totalPrice += originalTotalPrice;
      });
    }

    // Definir o valor do preço final
    this.finalPriceTarget.value = parseFloat(finalPrice.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }
  

  calculateInstallmentsValue() {
    let totalPrice = 0;
  
    // Percorra todos os elementos de totalPriceTargets e some os valores
    this.finalPriceTargets.forEach((finalPriceTarget) => {
      const currentFinalPrice = parseFloat(finalPriceTarget.value.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
      totalPrice += currentFinalPrice;
    });
  
    // Obter o número de parcelas do elemento de parcelas
    let installments = parseInt(this.installmentsTarget.value) || 1; // Defina 1 como valor padrão se o campo estiver vazio ou não numérico
  
    // Garanta que o número de parcelas seja pelo menos 1
    installments = Math.max(1, installments);
  
    let installmentsValue = 0;
    if (installments > 0) {
      // Calcular o valor da parcela com base no valor total e no número de parcelas
      installmentsValue = totalPrice / installments;
    }
  
    // Definir o valor do campo de valor das parcelas
    this.installmentsValueTarget.value = parseFloat(installmentsValue.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }
  

  updateInstallmentsValue() {
    this.calculateInstallmentsValue();
  }

  updateTotalAmount() {
    let totalAmount = 0;
    this.totalPriceTargets.forEach((totalPriceInput) => {
      const newTotalPrice = parseFloat(totalPriceInput.value.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
      totalAmount += newTotalPrice;
    });
  
    this.totalAmountTarget.value = parseFloat(totalAmount.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }

  updateinstallmentsValue() {
    let installmentsValue = 0;
    this.totalPriceTargets.forEach((totalPriceInput) => {
      const newTotalPrice = parseFloat(totalPriceInput.value.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
      installmentsValue += newTotalPrice;
    });
  
    this.installmentsValueTarget.value = parseFloat(installmentsValue.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  } 

  updatefinalPrice() {
    let finalPrice = 0;
    this.totalPriceTargets.forEach((totalPriceInput) => {
      const newTotalPrice = parseFloat(totalPriceInput.value.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
      finalPrice += newTotalPrice;
    });
  
    this.finalPriceTarget.value = parseFloat(finalPrice.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  } 
}
