import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["product", "quantity", "unitPrice", "quantity"]

  addProduct(event) {
    event.preventDefault()
    const productId = this.productTarget.value
    const quantity = this.quantityTarget.value
    const unitPrice = this.unitPriceTarget.value
    // Faça o que for necessário para adicionar o produto ao pedido
    // Isso pode incluir enviar uma solicitação AJAX para adicionar o produto
    // ou manipular o pedido localmente, dependendo da sua lógica.
  }


  add(event) {
    event.preventDefault()
    const orderItemId = event.currentTarget.getAttribute("data-order-item-id")
    const quantityElement = this.quantityTarget.find(q => q.getAttribute("data-order-item-id") === orderItemId)

    if (quantityElement) {
      let currentQuantity = parseInt(quantityElement.textContent, 10)
      currentQuantity++
      quantityElement.textContent = currentQuantity
    }
  }

  remove(event) {
    event.preventDefault()
    const orderItemId = event.currentTarget.getAttribute("data-order-item-id")
    const quantityElement = this.quantityTarget.find(q => q.getAttribute("data-order-item-id") === orderItemId)

    if (quantityElement) {
      let currentQuantity = parseInt(quantityElement.textContent, 10)
      if (currentQuantity > 0) {
        currentQuantity--
        quantityElement.textContent = currentQuantity
      }
    }
  }
}

