import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["orderSelect", "productSelect", "customerSelect"];

  updateProducts(event) {
    const orderId = event.target.value;

    fetch(`/assistencia-tecnica/products?id=${orderId}`)
      .then(response => response.json())
      .then(data => {

        const productSelect = this.productSelectTarget;

        if (productSelect) {
          // Limpa as opções existentes
          productSelect.innerHTML = "";

          // Adiciona as novas opções
          data.forEach(product => {
            const option = document.createElement("option");
            option.value = product.id;
            option.textContent = product.name;
            productSelect.appendChild(option);
          });
        } else {
          console.error("Error: Missing target element 'productSelect' for 'technical_assistances' controller");
        }
      })
      .catch(error => console.error("Error fetching products:", error));
  }

  updateCustomers(event) {
    const orderId = event.target.value;
  
    fetch(`/assistencia-tecnica/customers?id=${orderId}`)
      .then(response => response.json())
      .then(data => {
  
        const customerSelect = this.customerSelectTarget;
  
        if (customerSelect) {
          // Limpa as opções existentes
          customerSelect.innerHTML = "";
  
          // Verifica se os dados são um objeto
          if (typeof data === 'object') {
            const option = document.createElement("option");
            option.value = data.value;
            option.textContent = data.label;
            customerSelect.appendChild(option);
          } else {
            console.error("Error: Invalid data format for customers");
          }
        } else {
          console.error("Error: Missing target element 'customerSelect' for 'technical_assistances' controller");
        }
      })
      .catch(error => console.error("Error fetching customers:", error));
  }
}