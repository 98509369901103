import { Controller } from "stimulus";

export default class extends Controller {
  formatCurrency(event) {
    const input = event.target;
    let value = input.value.replace(/\D/g, '');

    // Se o valor for zero, não exiba nada
    if (value === '0') {
      input.value = '';
      return;
    }

    if (value === '') {
      return;
    }

    // Obter parte inteira e decimal
    let integerPart = value.slice(0, -2);
    const decimalPart = value.slice(-2);

    // Adicionar separador de milhares
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Formatar a parte decimal
    const formattedValue = integerPart + ',' + decimalPart;

    input.value = formattedValue;
  }
}