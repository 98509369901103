import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate", "query"];

  connect() {
    this.fillFormFields();
  }

  fillFormFields() {
    const params = new URLSearchParams(window.location.search);
    this.startDateTarget.value = params.get("start_date") || "";
    this.endDateTarget.value = params.get("end_date") || "";
    this.queryTarget.value = params.get("query") || "";
  }

  handleSubmit() {
    const formData = new FormData(this.element);
    const searchParams = new URLSearchParams(formData).toString();
    window.location = `${this.element.action}?${searchParams}`;
  }
}
