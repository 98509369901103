import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['rootDiv'];

  initialize() {
    console.log('Initializing Controller')
    this.rootDivTarget.innerHTML = 'Inicializando'
  }
  connect() {
    console.log('Initialized Controller')
    this.rootDivTarget.innerHTML = 'Inicializado'
  }
}
