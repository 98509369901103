import { Controller } from "stimulus";

export default class extends Controller {
  togglePasswordVisibility() {
    const passwordInput = this.element.querySelector("#user_password");

    passwordInput.type = passwordInput.type === "password" ? "text" : "password";
  }

  togglePasswordResetTokenVisibility() {
    const passwordResetTokenInput = this.element.querySelector("#user_password_reset_token");

    passwordResetTokenInput.type = passwordResetTokenInput.type === "password" ? "text" : "password";
  }
}